/** @typedef {import('@/types/models').Contract} Contract */
/** @typedef {import('@/types/models').Contract_V2} Contract_V2 */
/** @typedef {import('@/types/models').ContractFields} ContractFields */

import axios from '@/common/utils/axios'

export default {
  QUERY_PARAM_SYNC_VALIDATION: 'sync_validation',

  /**
   * @param {number} id
   * @returns {Promise<{ data: Contract }>}
   */
  async find(id) {
    return axios.get(`/xhr/contracts/${id}`)
  },

  /**
   * @returns {Promise<{ data: Contract[] }>}
   */
  async findAll() {
    return axios.get('/xhr/contracts')
  },

  /**
   * Retrieves information about all the available contracts the user has in the SC account together with broker and
   * sub-broker contracts.
   *
   * @returns {Promise<Contract_V2[]>}
   */
  async fetchAll_v2(onlyActive = true) {
    const params = onlyActive ? { is_active: true } : undefined
    return axios
      .get('/api/v2/contracts', { params })
      .then(({ data: { contracts } }) => contracts)
      .catch(() => [])
  },

  /**
   * @param {{ carrier: number, fields: ContractFields }} payload
   * @returns {Promise<{ data: Contract }>}
   */
  async create(payload) {
    return axios.post('/xhr/contracts', payload)
  },

  /**
   * @param {number} id
   * @param {{ is_active: boolean } | { fields: ContractFields }} payload
   * @param {boolean} shouldValidate - if specified the contract credentials will be validated synchronously.
   * @param {boolean} patch - if specified, the update will use PATCH instead of PUT
   * @returns {Promise<{ data: Contract }>}
   */
  async update(id, payload, shouldValidate = false, patch = false) {
    const options = shouldValidate ? { params: { [this.QUERY_PARAM_SYNC_VALIDATION]: true } } : undefined
    if (patch) {
      return axios.patch(`/xhr/contracts/${id}`, payload, options)
    }
    return axios.put(`/xhr/contracts/${id}`, payload, options)
  },

  /**
   * @returns {Promise<void>}
   */
  async destroy(id) {
    await axios.delete(`/xhr/contracts/${id}`)
  },

  /**
   * @param {number} id
   * @param {File} file
   * @returns {Promise<void>}
   */
  async uploadContractPrices(id, file) {
    const payload = new FormData()
    payload.append('file', file)

    return axios.post(`/api/v2/contracts/${id}/pricing`, payload)
  },

  async register(payload) {
    return axios.post(`/xhr/contracts/${payload.carrier}/register`, payload)
  },
}
