import type { RouteRecordRaw } from 'vue-router'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { PRODUCTS_BETA_FEATURE } from '@/features/products/beta-features'
import { SETTINGS_PRODUCTS_ROUTE } from '@/application/routes/settings.routes.names'

export default [
  {
    name: SETTINGS_PRODUCTS_ROUTE,
    path: '/settings/products',
    component: () => import('@/features/products/pages/ProductsPage.vue'),
    beforeEnter: betaGuard(PRODUCTS_BETA_FEATURE, SHIPPING_INCOMING_ORDERS_ROUTE),
    meta: {
      pageTitle: () => 'Products',
      permissions: ['manage_products'],
      parent: 'settings-products',
    },
  },
] satisfies RouteRecordRaw[]
