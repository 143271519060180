import { type RouteRecordRaw, RouterView, type RouteLocationNormalized } from 'vue-router'

import { getAbsoluteBackendURL } from '@/common/utils/backend'
import i18n from '@/application/i18n/i18n'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { BETA_FEATURE_CHARGEBEE_TRANSACTIONAL, CHARGEBEE_SUBSCRIPTION_ENABLED } from '@/features/financial/beta-features'
import store from '@/common/stores/store'
export const SETTINGS_FINANCIAL_ROUTE = 'financial'
export const SETTINGS_FINANCIAL_INVOICES_ROUTE = 'financial.invoicing.list'
export const SETTINGS_FINANCIAL_INVOICE_DETAILS_ROUTE = 'financial.invoicing.detail'
export const SETTINGS_FINANCIAL_INVOICES_HISTORY_ROUTE = 'financial.invoicing.history'
export const SETTINGS_FINANCIAL_PAYMENTS_ROUTE = 'financial.payments.recurringcontract'
export const SETTINGS_FINANCIAL_INVOICE_PAY_ROUTE = 'financial.invoicing.pay'

// Revenue Service Routes
export const SETTINGS_FINANCIAL_REVENUE_INVOICES_ROUTES = 'financial.revenue.invoicing.list'
export const SETTINGS_FINANCIAL_REVENUE_TRANSACTIONAL_INVOICES_ROUTE = 'financial.revenue.invoicing.transactional.list'
export const SETTINGS_FINANCIAL_REVENUE_TRANSACTIONAL_INVOICE_DETAILS_ROUTE = 'financial.invoicing.transactional.detail'
export const SETTINGS_FINANCIAL_REVENUE_SERVICE_INVOICES_ROUTE = 'financial.revenue.invoicing.list'
export const SETTINGS_FINANCIAL_REVENUE_SERVICE_INVOICE_DETAILS_ROUTE = 'financial.revenue.invoicing.detail'

function isChargebeeSubscriptionBetaFeatureEnabled() {
  return store.getters.hasBetaFeature(CHARGEBEE_SUBSCRIPTION_ENABLED)
}

export default {
  name: SETTINGS_FINANCIAL_ROUTE,
  path: useNewNavigation ? '/account/financial' : 'financial',
  redirect: () => ({
    name: isChargebeeSubscriptionBetaFeatureEnabled()
      ? SETTINGS_FINANCIAL_REVENUE_SERVICE_INVOICES_ROUTE
      : SETTINGS_FINANCIAL_INVOICES_ROUTE,
  }),
  component: () => import('@/features/financial/components/FinancesNav.vue'),
  children: [
    {
      name: SETTINGS_FINANCIAL_INVOICES_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/list' : 'invoices/list',
      component: () => import('@/features/financial/pages/InvoiceListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/invoices/list', '/account/financial/invoices/list'],
    },
    {
      name: SETTINGS_FINANCIAL_INVOICE_DETAILS_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/:invoiceRef/details' : 'invoices/:invoiceRef/details',
      component: () => import('@/features/financial/pages/InvoiceDetailPage.vue'),
      props: route => ({
        invoiceRef: route.params.invoiceRef,
      }),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/invoices/:invoiceRef/details', '/account/financial/invoices/:invoiceRef/details'],
    },
    {
      name: SETTINGS_FINANCIAL_INVOICES_HISTORY_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/:invoiceRef/history' : 'invoices/:invoiceRef/history',
      component: () => import('@/features/financial/pages/InvoiceHistoryPage.vue'),
      props: route => ({
        invoiceRef: route.params.invoiceRef,
      }),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/invoices/:invoiceRef/history', '/account/financial/invoices/:invoiceRef/history'],
    },
    {
      name: SETTINGS_FINANCIAL_INVOICE_PAY_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/:invoiceRef/pay' : 'invoices/:invoiceRef/pay',
      component: RouterView,
      beforeEnter: redirectToPayment,
      meta: {
        pageTitle: () => '',
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/invoices/:invoiceRef/pay', '/account/financial/invoices/:invoiceRef/pay'],
    },
    {
      name: SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
      path: useNewNavigation ? '/account/financial/payments/direct-debit' : 'payments/direct-debit',
      component: () => import('@/features/financial/pages/RecurringContractPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/payments/direct-debit', '/account/financial/payments/direct-debit'],
    },

    // Revenue Service Routes for ChargebeeTransactionalBetaFeature users
    {
      name: SETTINGS_FINANCIAL_REVENUE_TRANSACTIONAL_INVOICES_ROUTE,
      path: useNewNavigation ? '/account/financial/shipping-invoices/list' : 'shipping-invoices/list',
      component: () => import('@/features/financial/pages/RevenueInvoiceListPage.vue'),
      beforeEnter: betaGuard(BETA_FEATURE_CHARGEBEE_TRANSACTIONAL, SETTINGS_FINANCIAL_ROUTE),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
      },
      alias: ['/settings/financial/shipping-invoices/list', '/account/financial/shipping-invoices/list'],
    },
    {
      name: SETTINGS_FINANCIAL_REVENUE_TRANSACTIONAL_INVOICE_DETAILS_ROUTE,
      path: useNewNavigation ? '/account/financial/shipping-invoices/:invoiceUuid/details' : 'shipping-invoices/:invoiceUuid/details',
      component: () => import('@/features/financial/pages/RevenueInvoiceDetailPage.vue'),
      beforeEnter: betaGuard(BETA_FEATURE_CHARGEBEE_TRANSACTIONAL, SETTINGS_FINANCIAL_ROUTE),
      props: route => ({
        invoiceUuid: route.params.invoiceUuid,
      }),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/shipping-invoices/:invoiceUuid/details', '/account/financial/shipping-invoices/:invoiceUuid/details'],
    },
    // Revenue Service Routes for ChargebeeSubscriptionManagementBetaFeature users
    {
      name: SETTINGS_FINANCIAL_REVENUE_SERVICE_INVOICES_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/list' : 'invoices/list',
      component: () => import('@/features/financial/pages/RevenueInvoiceListPage.vue'),
      beforeEnter: betaGuard(CHARGEBEE_SUBSCRIPTION_ENABLED, SETTINGS_FINANCIAL_ROUTE),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
      },
      alias: ['/settings/financial/invoices/list', '/account/financial/invoices/list'],
    },
    {
      name: SETTINGS_FINANCIAL_REVENUE_SERVICE_INVOICE_DETAILS_ROUTE,
      path: useNewNavigation ? '/account/financial/invoices/:invoiceUuid/details' : 'invoices/:invoiceUuid/details',
      component: () => import('@/features/financial/pages/RevenueInvoiceDetailPage.vue'),
      beforeEnter: betaGuard(CHARGEBEE_SUBSCRIPTION_ENABLED, SETTINGS_FINANCIAL_ROUTE),
      props: route => ({
        invoiceUuid: route.params.invoiceUuid,
      }),
      meta: {
        pageTitle: () => i18n.t('Financial'),
        permissions: ['manage_invoices'],
        parent: 'account-financial',
      },
      alias: ['/settings/financial/invoices/:invoiceUuid/details', '/account/financial/invoices/:invoiceUuid/details'],
    },
  ],
} satisfies RouteRecordRaw

function redirectToPayment(to: RouteLocationNormalized) {
  const promise = Promise.resolve()
  const { params, query } = to

  let url = getAbsoluteBackendURL(
    `/payments/initiate/?item_id=${params.invoiceRef}&item_content_type=invoicing.invoice`,
  )

  if (query.return_url) {
    const returnUrl = Array.isArray(query.return_url) ? query.return_url[0] : query.return_url
    if (returnUrl !== null) {
      url += '&return_url=' + encodeURIComponent(returnUrl)
    }
  }

  if (query.recurring) {
    const recurring = Array.isArray(query.recurring) ? query.recurring[0] : query.recurring
    if (recurring !== null) {
      url += '&recurring=' + encodeURIComponent(recurring)
    }
  }

  // The redirect here does not work in Safari 13.1 and above, unless we force a temporary pause before changing the
  // URL. See https://sendcloud.atlassian.net/browse/SC-28761
  setTimeout(() => {
    window.location.href = url
  }, 1000)

  // ... we intentionally don't keep our promise,
  // the redirect will take over
  return promise
}
