// To be removed after new_return_tabs is released
export enum ReturnListTab {
  INCOMING = 'incoming',
  NOT_PROCESSED = 'notprocessed',
  CANCELLED = 'cancelled',
  REQUESTS = 'requests',
  IN_TRANSIT = 'in_transit',
  RETURNED = 'returned',
  COMPLETED = 'completed',
  REJECTED_CANCELLED = 'rejected_cancelled',
}

export enum NewReturnListTab {
  REQUESTS = 'requests',
  IN_TRANSIT = 'in_transit',
  RETURNED = 'returned',
  COMPLETED = 'completed',
  REJECTED_CANCELLED = 'rejected_cancelled',
}

export enum ReturnStatus {
  OPEN = 'open',
  REQUESTED = 'requested',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAYMENT_FAILED = 'payment_failed',
  REQUEST_REJECTED = 'request_rejected',
}
