import i18n from '@/application/i18n/i18n'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'
import { RouterView, type RouteRecordRaw } from 'vue-router'

import fallbackRoute from '@/application/routes/fallback-route'
import accountSettingsRoute, { SETTINGS_ACCOUNT_ROUTE } from '@/features/account/routes'
import addressesRoutes from '@/features/addresses/routes'
import boxesRoutes from '@/features/boxes/routes'
import carrierRatesRoute from '@/features/carrier-rates/routes'
import financialRoutes from '@/features/financial/routes'
import integrationsRoutes from '@/features/integrations/routes'
import returnDefaultsRoutes from '@/features/return-defaults/routes'
import returnRulesRoutes from '@/features/return-rules/routes'
import returnSettingsRoutes from '@/features/return-portal/routes'
import addressbookRoutes from '@/features/saved-contacts/routes'
import subscriptionRoutes from '@/features/subscription/routes'
import usersRoutes from '@/features/users-and-roles/routes'
import checkoutRoutes from '@/features/dynamic-checkout/routes'
import brandsRoutes from '@/features/brands/routes'
import carriersRoutes from '@/features/carriers/routes'
import checkoutRulesRoutes from '@/features/dynamic-checkout-rules/routes'
import ratesRoute from '@/features/rates/routes'
import shippingRulesRoutes from '@/features/shipping-rules/routes'
import trackingMessagesRoutes, {
  SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE,
  SETTINGS_NOTIFY_TRACKING_ROUTE,
} from '@/features/tracking-messages/routes'
import trackingMessagesRoutesV2 from '@/features/tracking-messages-v2/routes'
import trackingPagesRoutes from '@/features/tracking-pages/routes'
import trackingWidgetRoutes from '@/features/tracking-widget/routes'
import {
  SETTINGS_PACKGO_ROUTE,
  SETTINGS_PRINTING_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_SHIPPING_DEFAULTS_ROUTE,
} from '@/application/routes/settings.routes.names'

export default [
  {
    name: SETTINGS_ROUTE,
    path: '/settings',
    redirect: { name: SETTINGS_ACCOUNT_ROUTE },
    component: () => import('@/application/pages/UserSettings.vue'),
    children: [
      accountSettingsRoute,
      addressbookRoutes,
      addressesRoutes,
      boxesRoutes,
      brandsRoutes,
      carriersRoutes,
      checkoutRulesRoutes,
      checkoutRoutes,
      financialRoutes,
      integrationsRoutes,
      ratesRoute,
      returnRulesRoutes,
      returnSettingsRoutes,
      returnDefaultsRoutes,
      shippingRulesRoutes,
      ...subscriptionRoutes,
      usersRoutes,
      carrierRatesRoute,
      {
        name: SETTINGS_NOTIFY_TRACKING_ROUTE,
        path: useNewNavigation ? 'tracking' : 'track-and-trace',
        redirect: { name: SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE },
        component: RouterView,
        children: [
          trackingMessagesRoutes,
          trackingMessagesRoutesV2,
          trackingPagesRoutes,
          trackingWidgetRoutes,
        ],
      },

      {
        name: SETTINGS_SHIPPING_DEFAULTS_ROUTE,
        path: useNewNavigation ? '/shipping/shipping-defaults' : 'shipping',
        component: () => import('@/features/shipping-defaults/pages/ShippingDefaultsSettingsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Shipping defaults'),
          permissions: ['configure_shipping'],
          parent: 'shipping-defaults',
        },
        alias: ['/settings/shipping', '/shipping/shipping-defaults'],
      },

      {
        name: SETTINGS_PACKGO_ROUTE,
        path: 'packgo',
        component: () => import('@/features/packgo/pages/PackGoSettingsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Pack & Go'),
          permissions: ['configure_packing'],
          parent: 'settings-packgo',
        },
      },

      {
        name: SETTINGS_PRINTING_ROUTE,
        path: useNewNavigation ? '/shipping/printing' : '/settings/printing',
        component: () => import('@/features/printing/pages/PrintSettingsPage.vue'),
        meta: {
          pageTitle: () => useNewNavigation ? i18n.t('aside.navigation.printing_options') : i18n.t('Print settings'),
          permissions: ['configure_packing'],
          parent: 'shipping-printing',
        },
        alias: ['/settings/printing', '/shipping/printing'],
      },

      {
        ...fallbackRoute,
        name: `${fallbackRoute.name}-settings`,
        meta: {
          ...fallbackRoute.meta,
          pageTitle: () => i18n.t(fallbackRoute.meta.pageTitle),
        },
      },
      // TO DO NOT APPEND ROUTES BELOW THE FALLBACK ROUTE.
    ],
    meta: {
      pageTitle: () => i18n.t('Settings'),
      permissions: [
        /* Empty. This is just a redirect. */
      ],
    },
  }] satisfies RouteRecordRaw[]
