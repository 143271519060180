import { ref } from 'vue'
import { RouterView, type RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { packGoRouteGuard, packGoGuardUpgrade } from '@/features/packgo/guards/packgo-guards'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'

export const PACKGO_QUEUE_ROUTE = 'packgo.queue'
export const PACKGO_FEATURE_TEASER = 'packgo.feature.teaser'

const previousRouteName = ref('')

export default [
  // We deleted the old Pack&Go, but we keep the redirect, in case users still use the /new-packgo route
  {
    name: 'new-packgo',
    path: '/new-packgo',
    component: RouterView,
    redirect: { name: PACKGO_QUEUE_ROUTE },
    children: [
      {
        name: 'new-packgo.queue',
        path: '/new-packgo/:pathMatch(.*)*',
        redirect: { name: PACKGO_QUEUE_ROUTE },
        meta: {
          pageTitle: () => i18n.t('Pack & Go'),
          permissions: ['process_orders'],
          parent: 'shipping-pack-go',
        },
      },
    ],
  },
  {
    name: 'packgo',
    path: useNewNavigation ? '/shipping/packgo' : '/packgo',
    beforeEnter: packGoRouteGuard,
    component: RouterView,
    redirect: { name: PACKGO_QUEUE_ROUTE },
    children: [
      {
        name: PACKGO_QUEUE_ROUTE,
        path: useNewNavigation ? '/shipping/packgo/queue' : '/packgo/queue',
        props: route => ({
          searchQuery: route.query.q,
          previousRouteName: previousRouteName.value,
        }),
        beforeEnter: (_to, from) => {
          previousRouteName.value = from.name?.toString() || ''
        },
        component: () => import('@/features/packgo/pages/PackGoOrderQueuePage.vue'),
        meta: {
          pageTitle: () => i18n.t('Pack & Go'),
          permissions: ['process_orders'],
          parent: 'shipping-pack-go',
        },
        alias: ['/packgo', '/packgo/queue', '/shipping/packgo/queue'],
      },
    ],
  },
  {
    name: PACKGO_FEATURE_TEASER,
    path: useNewNavigation ? '/shipping/packgo/upgrade-your-plan' : '/shipping/packgo/upgrade-your-plan',
    beforeEnter: packGoGuardUpgrade,
    component: () => import('@/features/packgo/pages/PackGoFeatureteaserPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Pack & Go'),
      permissions: ['process_orders'],
      parent: 'shipping-pack-go',
    },
    alias: ['/packgo/upgrade-your-plan', '/shipping/packgo/upgrade-your-plan'],
  },
] satisfies RouteRecordRaw[]
