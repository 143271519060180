import { CONTRACTS_FETCH_CONTRACTS, CONTRACTS_FETCH_CONTRACT, CONTRACTS_FETCH_CONTRACTS_V2 } from './action.types'
import {
  CONTRACTS_SET_CONTRACT_DETAILS,
  CONTRACTS_SET_CONTRACTS,
  CONTRACTS_SET_CONTRACTS_V2,
} from './mutation.types'

import ContractApi from '@/features/carriers/api/contract.api'
import type { Contract, Contract_V2 } from '@/features/carriers/types/contract.types'
import type { Commit } from 'vuex'
import type { Carrier } from '@/features/carriers/types/carrier.types'

interface CarrierContractsState {
  contracts: Contract[]
  contractsV2: Contract_V2[]
  contract: Contract | null
}

const initialState: CarrierContractsState = {
  contracts: [],
  contractsV2: [],
  contract: null,
}

type Getters = {
  contracts: (state: CarrierContractsState) => Contract[]
  contractsV2: (state: CarrierContractsState) => Contract_V2[]
  contract: (state: CarrierContractsState) => Contract | null
  // carrierById is declared in global store so we don't use it in the getters object below
  carrierById: (id: number) => Carrier
}

const getters = {
  contracts: (state: CarrierContractsState) => state.contracts,
  contractsV2: (state: CarrierContractsState) => state.contractsV2,
  contract: (state: CarrierContractsState) => state.contract,
}

const actions = {
  async [CONTRACTS_FETCH_CONTRACTS]({ commit, getters }: { commit: Commit, getters: Getters }) {
    const response = await ContractApi.findAll()

    const contracts = response.data

    const sortedContracts = contracts.sort((contractA, contractB) => {
      const carrierNameA = getters.carrierById(contractA.carrier).name
      const carrierNameB = getters.carrierById(contractB.carrier).name

      let sortResult = carrierNameA.localeCompare(carrierNameB)

      if (!sortResult) {
        sortResult = contractA.client_id.localeCompare(contractB.client_id)
      }

      return sortResult
    })

    commit(CONTRACTS_SET_CONTRACTS, sortedContracts)
  },

  async [CONTRACTS_FETCH_CONTRACTS_V2]({ commit }: { commit: Commit }, onlyActive: boolean = true) {
    const contracts = await ContractApi.fetchAll_v2(onlyActive)

    contracts.sort((contractA, contractB) => {
      // Compare a boolean is_default
      if (contractA.is_default !== contractB.is_default) {
        return contractB.is_default ? -1 : 1
      }

      // Compare client_id existence
      if (!!contractA.client_id !== !!contractB.client_id) {
        return contractB.client_id ? -1 : 1
      }

      // Compare names (strings)
      return contractA.name.localeCompare(contractB.name)
    })

    commit(CONTRACTS_SET_CONTRACTS_V2, contracts)
  },

  async [CONTRACTS_FETCH_CONTRACT]({ commit }: { commit: Commit }, contractId: number) {
    const response = await ContractApi.find(contractId)
    commit(CONTRACTS_SET_CONTRACT_DETAILS, response.data)
  },
}

const mutations = {
  [CONTRACTS_SET_CONTRACTS](state: CarrierContractsState, contracts: Contract[]) {
    state.contracts = contracts
  },

  [CONTRACTS_SET_CONTRACTS_V2](state: CarrierContractsState, contracts: Contract_V2[]) {
    state.contractsV2 = contracts
  },

  [CONTRACTS_SET_CONTRACT_DETAILS](state: CarrierContractsState, contract: Contract) {
    state.contract = contract
  },
}

export default {
  state: initialState,
  getters,
  mutations,
  actions,
}
