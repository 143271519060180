import type { RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { BETA_FEATURES } from '@/features/insurance/beta-features'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import type { RouteButton } from '@/common/types/route.types'

export const SHIPPING_PROTECTION_ROUTE = 'shipping-protection'

const howToMakeClaimButton: RouteButton = {
  id: 'open-help-center-xcover',
  title: () => i18n.t('settings.shipping.protection.header.button.label'),
  name: 'Help center button',
  href: 'https://support.sendcloud.com/hc/articles/23126505906449',
  newTab: true,
  mode: 'borderless',
  icon: faInfoCircle,
  align: 'right',
  // TODO: analytics?
  // customClick: () => ShippingRulesAnalytics.openHelpCenter('Shipping rules list'),
  permissions: ['configure_shipping'],
}

export default [
  {
    name: SHIPPING_PROTECTION_ROUTE,
    path: '/shipping/shipping-protection',
    component: () => import('@/features/insurance/pages/ShippingProtectionPage.vue'),
    beforeEnter: betaGuard(BETA_FEATURES.SHIPPING_PROTECTION_PAGE, SHIPPING_INCOMING_ORDERS_ROUTE),
    meta: {
      buttons: [howToMakeClaimButton],
      pageTitle: () => i18n.t('settings.shipping.protection.header.title'),
      permissions: ['configure_shipping'],
      parent: SHIPPING_PROTECTION_ROUTE,
    },
  },

] satisfies RouteRecordRaw[]
