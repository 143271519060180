/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import { RouterView, type RouteRecordRaw } from 'vue-router'

export const SETTINGS_RETURN_DEFAULTS_ROUTE = 'return-defaults.add'

export default {
  path: 'return-defaults',
  component: RouterView,
  children: [
    {
      name: SETTINGS_RETURN_DEFAULTS_ROUTE,
      path: '',
      component: () => import('@/features/return-defaults/pages/ReturnDefaultsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Return defaults'),
        permissions: ['configure_returns'],
        parent: 'returns-default',
      },
    },
  ],
} satisfies RouteRecordRaw
