import Segment from '@/common/utils/tracking/segment'
import Google from '@/common/utils/tracking/google'

const satismeterOnly = {
  integrations: {
    SatisMeter: true,
    All: false,
  },
}

const facebookPixelOnly = {
  integrations: {
    'Facebook Pixel': true,
    'All': false,
  },
}

type VolumeSelectedBucket = {
  id: string
  lowestVolume: number
  eventName: string
}

type StepsCompleted = Record<string, boolean>

export default {
  /**
   * Tracking button clicked
   */
  onboardingButtonClicked(
    buttonName: string,
    buttonDescription: string,
    destinationUrl?: string,
  ): void {
    Segment.track('Onboarding Wizard - Button clicked', {
      button_name: buttonName,
      button_description: buttonDescription,
      destination_url: destinationUrl,
    })
  },

  /**
   * Tracking information about Your Details Form
   */
  onboardingWizardYourDetailsSubmitted(
    userId: string | number,
    volumeSelectedBucket: VolumeSelectedBucket,
  ): void {
    Segment.track(
      'Onboarding Wizard - ' + volumeSelectedBucket.eventName,
      {
        'Bucket copy': volumeSelectedBucket.id,
        'Shipping volume': volumeSelectedBucket.lowestVolume,
      },
      facebookPixelOnly,
    )

    Google.trackEvent(
      'shipping_volume_selected',
      volumeSelectedBucket.eventName,
      volumeSelectedBucket.id,
      volumeSelectedBucket.lowestVolume,
    )

    // Track signups on PartnerstackJS
    if (window.growsumo && userId) {
      window.growsumo.data = {
        ...window.growsumo.data,
        name: userId,
        email: `${userId}@sendcloud.com`,
        customer_key: String(userId),
      }
      window.growsumo.createSignup()
    }
  },

  /**
   * Tracking information about Frontend Errors in Your Details Form
   *
   */

  onboardingWizardYourDetailsFrontEndErrors(errorMessage: { [key: string]: string[] | string } | string[]): void {
    Segment.track(
      'Onboarding Wizard - Your Details - Frontend Errors Messages',
      {
        Errors: errorMessage,
      },
    )
  },

  /**
   * Tracking information about Errors in Your Details Form
   *
   */

  onboardingWizardYourDetailsBackendErrors(errorMessage: { [key: string]: string[] | string } | string[]): void {
    Segment.track(
      'Onboarding Wizard - Your Details - Backend Errors Messages',
      {
        Errors: errorMessage,
      },
    )
  },

  /**
   * % of completion of the Wizard for each user
   */
  onboardingWizardStepCompleted(
    percentage: string,
    stepId: string,
    stepsCompleted: StepsCompleted,
  ): void {
    Segment.track(
      'Onboarding Wizard - Steps Completed and percentage ',
      {
        '% of Steps Completed': percentage,
        'Step Completed': stepId,
        'Total Steps Completed': stepsCompleted,
      },
    )
  },

  /**
   * Create Brand Submitted Errors
   */
  onboardingWizardBrandErrors(errors: { [key: string]: string[] | string } | string[]): void {
    Segment.track('Onboarding Wizard - Create Brand -- error submitted', {
      'Errors frontend and backend': errors,
    })
  },

  /**
   * User completes the onboarding wizard
   */
  onboardingWizardCompleted(): void {
    Segment.track('Onboarding Wizard - Completed ', {}, satismeterOnly)
  },

  /**
   * Onboarding wizard skipped by agent
   */
  onboardingWizardSkippedByAgent(): void {
    Segment.track('Onboarding Wizard - Skipped By Agent')
  },
}
