import i18n from '@/application/i18n/i18n'

// This is a copy from the constants in the notify backend in
// python/sendcloud/notify/constants.py
import { DIRECTION_INCOMING, DIRECTION_OUTGOING } from '@/app/common/constants'
import type {
  TrackingMessagePostPurchaseType,
  TrackingMessagesDirection,
} from '@/features/tracking-messages/types/tracking-messages.types'
import type { NotifyStatus } from '@/types/models'
import type { ParcelDirection } from '@/types/shipping.models'

export const STATUS_FRIENDLY_NAME: {
  [k in ParcelDirection]: {
    id: NotifyStatus
    label: string
  }[]
} = {
  o: [
    { id: 'READY_FOR_SHIPMENT', label: i18n.t('Ready for shipment') },
    { id: 'OUT_FOR_DELIVERY', label: i18n.t('Out for delivery') },
    { id: 'DELAYED', label: i18n.t('Delayed') },
    { id: 'READY_FOR_PICK_UP', label: i18n.t('Ready for pick-up') },
    { id: 'DELIVERED', label: i18n.t('Delivered') },
  ],
  i: [
    { id: 'LABEL_CREATED', label: i18n.t('Label Created') },
    { id: 'HANDED_IN_AT_DROP_OFF', label: i18n.t('Handed in at drop-off') },
    { id: 'RETURN_RECEIVED', label: i18n.t('Return received') },
  ],
  f: [
    { id: 'FOLLOW_UP', label: i18n.t('settings.tracking.messages.email.follow_up.input_template_name') },
  ],
}

export const ACTIVE_STATUS_TEXTS = {
  [DIRECTION_OUTGOING]: {
    READY_FOR_SHIPMENT: i18n.t(
      'When the label is generated within the Sendcloud panel, this email is sent with a 10 minute delay',
    ),
    OUT_FOR_DELIVERY: i18n.t('When the carrier receives the shipment, this email is sent'),
    READY_FOR_PICK_UP: i18n.t('When the shipment is available at a pick-up point, this email is sent'),
    DELIVERED: i18n.t('When the carrier successfully delivered the shipment, this email is sent'),
    DELAYED: i18n.t('When the shipment is delayed by the carrier, this email is sent'),
  },
  [DIRECTION_INCOMING]: {
    LABEL_CREATED: i18n.t('When the customer successfully created a label in the portal, this email is sent'),
    HANDED_IN_AT_DROP_OFF: i18n.t(
      'Handed in at drop-off: when the customer successfully gave the shipment to the carrier, this email is sent',
    ),
    RETURN_RECEIVED: i18n.t('When the return is successfully delivered to the return address, this email is sent'),
  },
}

export const TRACKING_MESSAGE_DEFAULTS = {
  PREVIEW_ENABLED: ['EMAIL'],
}

export const MESSAGE_TYPES = ['EMAIL', 'SMS', 'WHATSAPP']

export const MESSAGE_TYPE_LABEL = {
  LOWERCASE: {
    EMAIL: i18n.t('settings.tracking.messages.type.email.lowercase'),
    SMS: i18n.t('settings.tracking.messages.type.sms'),
    WHATSAPP: i18n.t('settings.tracking.messages.type.whatsapp'),
  },
  LOWERCASE_PLURALS: {
    EMAIL: i18n.t('settings.tracking.messages.type.email.lowercase.plural'),
    SMS: i18n.t('settings.tracking.messages.type.sms.plural'),
    WHATSAPP: i18n.t('settings.tracking.messages.type.whatsapp.plural'),
  },
  CAPITALIZE: {
    EMAIL: i18n.t('settings.tracking.messages.type.email.capitalize'),
    SMS: i18n.t('settings.tracking.messages.type.sms'),
    WHATSAPP: i18n.t('settings.tracking.messages.type.whatsapp'),
  },
  CAPITALIZE_PLURALS: {
    EMAIL: i18n.t('settings.tracking.messages.type.email.capitalize.plural'),
    SMS: i18n.t('settings.tracking.messages.type.sms.plural'),
    WHATSAPP: i18n.t('settings.tracking.messages.type.whatsapp.plural'),
  },
}

export const WHATSAPP_DIRECTION_MAP: TrackingMessagesDirection = {
  o: 'shipping',
  i: 'returns',
  f: 'follow_up',
}

export const POST_PURCHASE_DELAY_OPTIONS = [
  { id: 1, name: i18n.t('Immediately'), value: 'P0DT00H00M00S' },
  { id: 2, name: i18n.t('30 minutes'), value: 'P0DT00H30M00S' },
  { id: 3, name: i18n.t('1 hour'), value: 'P0DT01H00M00S' },
  { id: 4, name: i18n.t('3 hours'), value: 'P0DT03H00M00S' },
  { id: 5, name: i18n.t('12 hours'), value: 'P0DT12H00M00S' },
  { id: 6, name: i18n.t('1 day'), value: 'P1DT00H00M00S' },
  { id: 7, name: i18n.t('3 days'), value: 'P3DT00H00M00S' },
  { id: 8, name: i18n.t('1 week'), value: 'P7DT00H00M00S' },
  { id: 9, name: i18n.t('2 weeks'), value: 'P14DT00H00M00S' },
  { id: 10, name: i18n.t('1 month'), value: 'P30DT00H00M00S' },
]

export const POST_PURCHASE_TYPES: Record<'POST_PURCHASE_EMAIL' | 'TRUSTPILOT', TrackingMessagePostPurchaseType> = {
  POST_PURCHASE_EMAIL: 'postpurchase',
  TRUSTPILOT: 'trustpilot',
}

export const TRACKING_WHATSAPP_PERSONAL_ACCOUNT_FEATURE = 'tracking_messages_whatsapp_personal_account'

export const WHATSAPP_PROVIDER = {
  SENDCLOUD_WABA: 'sendcloud_cm',
  PERSONAL_WABA: 'personal_360d',
} as const

export const WHATSAPP_TEMPLATE_STATUSES = {
  DRAFT: 'draft',
  APPROVED: 'approved',
  IN_APPEAL: 'in_appeal',
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  REJECTED: 'rejected',
  PENDING_DELETION: 'pending_deletion',
  DELETED: 'deleted',
  DISABLED: 'disabled',
  PAUSED: 'paused',
  LIMIT_EXCEEDED: 'limit_exceeded',
} as const

export const TEMPLATE_STATUSES_CONSIDERED_PENDING = [
  WHATSAPP_TEMPLATE_STATUSES.PENDING,
  WHATSAPP_TEMPLATE_STATUSES.DRAFT,
  WHATSAPP_TEMPLATE_STATUSES.IN_APPEAL,
  WHATSAPP_TEMPLATE_STATUSES.SUBMITTED,
]

export const WHATSAPP_TEMPLATE_REJECTED_REASONS = {
  ABUSIVE_CONTENT: 'ABUSIVE_CONTENT',
  INVALID_FORMAT: 'INVALID_FORMAT',
  NONE: 'NONE',
  PROMOTIONAL: 'PROMOTIONAL',
  TAG_CONTENT_MISMATCH: 'TAG_CONTENT_MISMATCH',
  SCAM: 'SCAM',
} as const

// THERE WILL BE JUST ONE OF THE FIRST 4 KEYS
// THERE WILL BE JUST ONE OF THE LAST 2 KEYS
// WE COULD HAVE A UNION OF THE FIRST 4 KEYS AND THE LAST 2 KEYS
export const WHATSAPP_ALERTS_KEYS = {
  ACCOUNT_REGISTRATION_FAILED: 'account_registration_failed',
  ACCOUNT_UNREGISTERED: 'account_unregistered',
  WABA_NOT_VERIFIED: 'waba_not_verified',
  ACCOUNT_REGISTRATION_PENDING: 'account_registration_pending',
  DISPLAY_NAME_PENDING: 'display_name_pending',
  DISPLAY_NAME_REJECTED: 'display_name_rejected',
}

export const WHATSAPP_ACCOUNT_STATUSES = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected',
  FAILED: 'failed',
} as const

export const META_VERIFY_WHATSAPP_ACCOUNT_GUIDELINES_URL = 'https://www.facebook.com/business/help/2058515294227817?id=180505742745347'

export const META_DISPLAY_NAME_GUIDELINES_URL = 'https://www.facebook.com/business/help/757569725593362'

export const TEMPLATE_STATUS_BADGE_MAP: Record<string, {
  badgeStatus: string
  badgeTextKeyPath: string
}> = {
  [WHATSAPP_TEMPLATE_STATUSES.DRAFT]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.APPROVED]: {
    badgeStatus: 'success',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.approved',
  },
  [WHATSAPP_TEMPLATE_STATUSES.IN_APPEAL]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.SUBMITTED]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PENDING]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.REJECTED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.rejected',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PENDING_DELETION]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending_deletion',
  },
  [WHATSAPP_TEMPLATE_STATUSES.DELETED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.deleted',
  },
  [WHATSAPP_TEMPLATE_STATUSES.DISABLED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.disabled',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PAUSED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.paused',
  },
  [WHATSAPP_TEMPLATE_STATUSES.LIMIT_EXCEEDED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.limit_exceeded',
  },
}

export const EDIT_TEMPLATE_ACTION_TYPES = {
  TRACKING_LINK: 'trackingLink',
  WEBSITE: 'website',
  PHONE_NUMBER: 'phoneNumber',
  NO_BUTTON: 'noButton',
}

export const EDIT_TEMPLATE_ACTION_TYPE_SELECT_OPTIONS = [
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.TRACKING_LINK,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.tracking_link',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.WEBSITE,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.website',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.PHONE_NUMBER,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.phone_number',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.NO_BUTTON,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.no_button',
  },
]

export const CONFIRMATION_MODAL_TYPES = {
  ACTION_REQUIRED: 'action_required',
  RESTORE_TEMPLATE: 'restore_template',
  DISCARD_TEMPLATE: 'discard_template',
  SENT_FOR_APPROVAL: 'sent_for_approval',
  SEND_FOR_APPROVAL: 'send_for_approval',
}

const PERSONAL_PROVIDER_CONNECTION_ERRORS = {
  connected_to_another_brand: 'connected_to_another_brand',
  deleted: 'deleted',
}

export const PERSONAL_PROVIDER_CONNECTION_ERRORS_MAP = {
  [PERSONAL_PROVIDER_CONNECTION_ERRORS.connected_to_another_brand]: i18n.t('features.tracking_messages.whatsapp.personal_waba.alert.connected_to_another_brand'),
  [PERSONAL_PROVIDER_CONNECTION_ERRORS.deleted]: i18n.t('features.tracking_messages.whatsapp.personal_waba.alert.deleted'),
}

export const SHIPPING_TEMPLATES_KEYS = {
  READY_FOR_SHIPMENT: 'ready_for_shipment',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  DELAYED: 'delayed',
  READY_FOR_PICK_UP: 'ready_for_pick_up',
  DELIVERED: 'delivered',
} as const

export const RETURNS_TEMPLATES_KEYS = {
  LABEL_CREATED: 'label_created',
  HANDED_IN_AT_DROP_OFF: 'handed_in_at_drop_off',
  RETURN_RECEIVED: 'return_received',
} as const

export const FOLLOW_UP_KEYS = {
  POST_PURCHASE: 'post_purchase',
}

export const CUSTOM_TEMPLATES_BUTTON_TYPES = {
  URL: 'URL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  QUICK_REPLY: 'QUICK_REPLY',
} as const

export const CUSTOM_TEMPLATES_COMPONENT_TYPES = {
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BUTTONS: 'BUTTONS',
} as const
