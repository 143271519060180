import {
  boolean,
  object,
  string,
  number,
  literal,
  optional,
  array,
  nullable,
  maybe,
  type ParserResult,
} from '@/common/data-parsers'

export const WhatsappMediaItem = object(
  {
    url: string(),
    type: literal('audio', 'document', 'image', 'video', 'sticker'),
    mime_type: string(),
    // any message text attached to image, for example
    caption: nullable(string()),
    // only for type === document, thus maybe(): nullable + optional
    filename: maybe(string()),
    // is voice message or other audio
    voice: optional(boolean()),
  },
  'WhatsappMediaItem',
)
export type WhatsappMediaItem = ParserResult<typeof WhatsappMediaItem>

export const WhatsappMessageCta = object(
  {
    type: literal('PHONE_NUMBER', 'URL', 'QUICK_REPLY'),
    text: string(),
    url: optional(string()),
    phone_number: optional(string()),
  },
  'WhatsappMessageCta',
)
export type WhatsappMessageCta = ParserResult<typeof WhatsappMessageCta>

export const WhatsappMessage = object(
  {
    id: string(),
    direction: literal('inbound', 'outbound'),
    // can be empty string
    text: string(),
    sent_at: nullable(string()),
    read_at: nullable(string()),
    failed_at: nullable(string()),
    delivered_at: nullable(string()),
    created_at: string(),
    // TODO: after release, remove nullable (will always be an array, empty or with items)
    media: array(WhatsappMediaItem),
    // TODO: after release, remove nullable (will always be an array, empty or with items)
    calls_to_actions: array(WhatsappMessageCta),
  },
  'WhatsappMessage',
)
export type WhatsappMessage = ParserResult<typeof WhatsappMessage>

export const WhatsappChat = object(
  {
    id: string(),
    recipient_number: string(),
    recipient_name: string(),
    recipient_email: string(),
    last_message: WhatsappMessage,
    last_message_at: string(),
    created_at: string(),
    brand_id: number(),
    unread_messages_count: number(),
  },
  'WhatsappChat',
)
export type WhatsappChat = ParserResult<typeof WhatsappChat>

export const UnreadMessagesCounterResponse = object({
  count: number(),
})
export type UnreadMessagesCounterResponse = ParserResult<typeof UnreadMessagesCounterResponse>

export const ChatsPaginatedResponse = object({
  data: array(WhatsappChat),
  next: nullable(string()),
  previous: nullable(string()),
})
export type ChatsPaginatedResponse = ParserResult<typeof ChatsPaginatedResponse>

export const MessagesPaginatedResponse = object({
  data: array(WhatsappMessage),
  next: nullable(string()),
  previous: nullable(string()),
})
export type MessagesPaginatedResponse = ParserResult<typeof MessagesPaginatedResponse>
