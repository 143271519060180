import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'

export const SETTINGS_RATES_ROUTE = 'rates'
export const SETTINGS_PRICES_LIST_ROUTE = 'price-list'

const routes: RouteRecordRaw = {
  name: SETTINGS_RATES_ROUTE,
  path: useNewNavigation ? '/shipping/shipping-prices' : 'rates',
  component: () => import('@/features/rates/components/RatesNav.vue'),
  redirect: { name: SETTINGS_PRICES_LIST_ROUTE },
  children: [
    {
      name: SETTINGS_PRICES_LIST_ROUTE,
      path: useNewNavigation ? '/shipping/shipping-prices' : 'price-list',
      component: () => import('@/features/rates/pages/PriceListPage.vue'),
      meta: {
        pageTitle: () => useNewNavigation ? i18n.t('aside.navigation.shipping_prices') : i18n.t('Price list'),
        permissions: ['view_prices'],
        parent: 'shipping-prices',
      },
      alias: ['/rates', 'rates/price-list', '/settings/rates/price-list', '/shipping/shipping-prices'],
    },
  ],
}

export default routes
