<template>
  <template v-if="user">
    <AppPaymentWallWrapper />
    <AppLoadingBar />
    <template v-if="showOnboardingWizard && isOnboardingRoute">
      <router-view />
    </template>

    <template v-else>
      <div :class="containerClasses">
        <AppSidebar v-if="showNewNavigationSidebar" />
        <AppTopMenu
          v-if="showTopMenu"
          :use-new-navigation="useNewNavigation"
        />
        <main :class="wrapperClasses">
          <AppMaxVolumeAlert />
          <AppTitleBar v-if="!isSettingsRoute && !isConversationsRoute && !isOpportunityCenterRoute" />
          <router-view />
        </main>
      </div>
      <template v-if="userHasNewNotificationsBeta">
        <NotificationSidebar :is-using-new-nav="isUsingNewNav" />
        <NotificationWidget />
      </template>
      <NotificationPanel v-else />
      <StatuspageWidget v-if="settings" />
      <AppModal />
    </template>
    <TheHijackFooter v-if="isHijacked" />
  </template>

  <AppErrorPage v-else />
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, onBeforeMount, ref, provide } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Cookies from 'js-cookie'
import stringToBoolean from '@/common/utils/string-to-boolean'

import AppErrorPage from '@/application/components/AppErrorPage.vue'
import AppLoadingBar from '@/application/components/AppLoadingBar.vue'
import AppMaxVolumeAlert from '@/application/components/AppMaxVolumeAlert.vue'
import AppPaymentWallWrapper from '@/features/payment-wall/components/AppPaymentWallWrapper.vue'
import AppTitleBar from '@/application/components/AppTitleBar.vue'
import AppTopMenu from '@/application/components/old-navigation/AppTopMenu.vue'
import AppSidebar from '@/application/components/navigation/AppSidebar.vue'
import StatuspageWidget from '@/features/statuspage-widget/components/StatuspageWidget.vue'
import TheHijackFooter from '@/application/components/TheHijackFooter.vue'

import NavigationAnalytics from '@/application/utils/navigation.analytics'

import type { Settings, User } from '@/types/models'

import { SHIPPING_LABEL_DETAILS_ROUTE } from '@/features/label-details/routes'
import { RETURN_DETAILS_ROUTE } from '@/features/returns/routes'
import { SETTINGS_ROUTE } from '@/application/routes/settings.routes.names'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'
import { NEW_NAVIGATION } from '@/application/beta-features'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'
import { WHATSAPP_CONVERSATIONS_ROUTE } from '@/features/whatsapp-conversations/routes'
import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'

const NotificationPanel = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationPanel.vue'),
)
const NotificationSidebar = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationSidebar.vue'),
)
const NotificationWidget = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationWidget.vue'),
)
const AppModal = defineAsyncComponent(() => import('@/application/components/AppModal.vue'))

const isNewNavigationEnabled = () => {
  return import.meta.env.VUE_APP_NEW_NAVIGATION_ENABLED
}

const store = useStore()
const route = useRoute()

const user = computed<User>(() => store.getters.user)
const settings = computed<Settings>(() => store.getters.settings)
const isHijacked = computed<boolean>(() => store.getters.isHijacked)
const showOnboardingWizard = computed<boolean>(() => store.getters.showOnboardingWizard)
const userHasNewNotificationsBeta = computed<boolean>(() => store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA))

const userHasNewNavFeature = stringToBoolean(store.getters.hasBetaFeature(NEW_NAVIGATION))
const newNavEnvEnabled = stringToBoolean(isNewNavigationEnabled())

const isUsingNewNav = ref<boolean>(true)

// If user is staff user or has the feature enabled in local
const useNewNavigation = ref(userHasNewNavFeature || newNavEnvEnabled)

onBeforeMount(async () => {
  const preloader = document.querySelector('.preloader')
  if (preloader) {
    preloader.remove()
  }

  const cookieValue = Cookies.get('useNewNavigation')
  isUsingNewNav.value = cookieValue === 'true'
  if (!cookieValue) {
    isUsingNewNav.value = true
    Cookies.set('useNewNavigation', true, { expires: 14 })
  }
})

type ToggleNavigation = (payload: MouseEvent) => void

const toggleNavigation: ToggleNavigation = () => {
  isUsingNewNav.value = !isUsingNewNav.value
  NavigationAnalytics.trackSwitchNavigation(isUsingNewNav.value)
  Cookies.set('useNewNavigation', isUsingNewNav.value.toString(), { expires: 14 })

  const url = new URL(window.location.href)
  url.searchParams.set('new_nav', isUsingNewNav.value.toString())

  window.location.href = url.toString()
}

const showNewNavigationSidebar = computed<boolean>(() => {
  return isUsingNewNav.value
})

const showTopMenu = computed<boolean>(() => {
  return !isUsingNewNav.value
})

const containerClasses = computed(() => ({
  'main-container': true,
  'layout-with-sidebar': showNewNavigationSidebar.value,
  'layout-with-top-navigation': !showNewNavigationSidebar.value && showTopMenu.value,
}))

const wrapperClasses = computed<string>(() => {
  if (route.name === SHIPPING_LABEL_DETAILS_ROUTE || route.name === RETURN_DETAILS_ROUTE) {
    return 'main-wrapper main-wrapper--narrow'
  }
  if (route.name === SHIPPING_INCOMING_ORDERS_ROUTE) {
    return 'main-wrapper main-wrapper--overflow'
  }
  if (route.name === WHATSAPP_CONVERSATIONS_ROUTE) {
    return 'main-wrapper main-wrapper--even-padding'
  }

  return 'main-wrapper'
})

const isSettingsRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === SETTINGS_ROUTE)
})

const isOnboardingRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === ONBOARDING_WIZARD_ROUTE)
})

const isConversationsRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === WHATSAPP_CONVERSATIONS_ROUTE)
})

const isOpportunityCenterRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === OPPORTUNITY_CENTER_ROUTE)
})

provide('toggleNavigation', toggleNavigation)
</script>
