import dayjs, { type Dayjs, type ManipulateType } from 'dayjs'
import { useStore } from 'vuex'
import { computed } from 'vue'

export type DateInput = string | number | Date | Dayjs

function isValidTimeZone(timeZoneName: string): boolean {
  try {
    new Date().toLocaleString('en-US', { timeZone: timeZoneName })
    return true
  } catch {
    return false
  }
}

export function useDateTime() {
  const store = useStore()

  const timeZoneName = computed(() => {
    const newTimeZoneName: string = store?.getters.user?.time_zone_name
    return isValidTimeZone(newTimeZoneName) ? newTimeZoneName : undefined
  })

  const getDayjsDateWithTimeZoneOffset = (
    date: DateInput = new Date(),
    isIgnoringUserTimeZone = false,
  ) => {
    // if it's a dayjs object return it as it is since applying the timezone twice
    // would result in a wrong date
    if (dayjs.isDayjs(date)) {
      return date
    }

    const theDate = date instanceof Date ? date : new Date(date)

    if (Number.isNaN(theDate.getTime())) {
      // new Date(NaN) doesn't not raise any error but stores "Invalid Date" internally
      throw new Error(`Invalid date ${date}`)
    }

    if (timeZoneName.value && !isIgnoringUserTimeZone) {
      // We use 'en-US' in toLocaleString to ensure consistent formatting when adjusting for time zones,
      // this does NOT mean the date and time will always display in US format in the app.
      // The actual locale and format are controlled later by Day.js
      return dayjs(theDate.toLocaleString('en-US', { timeZone: timeZoneName.value }))
    }
    return dayjs(theDate)
  }

  const getCurrentDateTime = (isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(undefined, isIgnoringUserTimeZone)

  const convertToDayjs = (date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone)

  const addToDate = (amount: number, unit: ManipulateType, date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).add(amount, unit)

  const getCalendarDate = (date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).calendar()

  const getEndOfUnit = (unit: ManipulateType, date: DateInput = new Date(), isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).endOf(unit)

  const getFormattedDate = (format: string, date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).format(format)

  const getTimeFromNow = (date: DateInput, hideSuffix = false, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).fromNow(hideSuffix)

  const getStartOfUnit = (unit: ManipulateType, date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).startOf(unit)

  const subtractFromDate = (amount: number, unit: ManipulateType, date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).subtract(amount, unit)

  const toNativeJsDate = (date: DateInput) => {
    let jsDate

    if (date instanceof Date) {
      jsDate = date
    } else if (dayjs.isDayjs(date)) {
      jsDate = date.toDate()
    } else {
      jsDate = new Date(date)
    }

    if (Number.isNaN(jsDate.getTime())) {
      // new Date(NaN) doesn't raise any errors but stores "Invalid Date" internally
      throw new Error(`Invalid date ${date}`)
    }
    return jsDate
  }

  const getTimeToNow = (date: DateInput, isIgnoringUserTimeZone = false) =>
    getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).toNow()

  const isSameDay = (dateA: DateInput, dateB: DateInput) => {
    const dayJsDateA = getDayjsDateWithTimeZoneOffset(dateA)
    const dayJsDateB = getDayjsDateWithTimeZoneOffset(dateB)
    return dayJsDateA.isSame(dayJsDateB, 'day')
  }

  return {
    getCurrentDateTime,
    convertToDayjs,
    addToDate,
    getCalendarDate,
    getEndOfUnit,
    getFormattedDate,
    getTimeFromNow,
    getStartOfUnit,
    subtractFromDate,
    toNativeJsDate,
    getTimeToNow,
    isSameDay,
  }
}

// These formats will automatically adjust based on the locale due to the localizedFormat extension of Day.js
export const DATE_TIME_FORMATS = {
  SHORT: 'L', // 09/16/2024
  SHORT_WITH_TIME: 'L LT', // 09/16/2024 2:33 PM
  LONG: 'LL', // September 16, 2024
  LONG_WITH_TIME: 'LLL', // September 16, 2024 2:33 PM
}
